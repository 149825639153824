export const ctaTexts = [
  {
    title: 'utilizatori',
    number: 784,
    difference: 23.45,
  },
  {
    title: 'postari lunare',
    number: 5842,
    difference: 15.25,
  },
  {
    title: 'profiluri conectate',
    number: 3920,
    difference: 23.45,
  },
];
