import { VStack } from '@chakra-ui/react';
import React from 'react';
import { Helmet } from 'react-helmet-async';
import { Cta, Hero } from '../components';
import InfoSection from '../components/home/InfoSection';

const HomePage = () => {
  return (
    <VStack w={'full'} gap={'3rem'}>
      <Helmet>
        <title>Autopost - Partenerul tau la social media</title>
        {/* de adaugat datele pentru SEO */}
      </Helmet>
      <Hero />
      <Cta />
      <InfoSection
        image={'/images/home1.webp'}
        title={'Postează mai ușor și mai rapid'}
        text={
          'Cu ajutorul acestui serviciu, veți putea posta conținut pe toate platformele de social media relevante, inclusiv Facebook, Instagram, LinkedIn, TikTok, YouTube și multe altele. Nu va mai trebui să navigați prin diferite conturi și platforme, iar programarea și planificarea postărilor vor fi ușor de gestionat.'
        }
      />
      <InfoSection
        imageStart
        image={'/images/hom2.webp'}
        title={'Urmărește în timp real'}
        text={
          'Serviciul nostru vă va ajuta să economisiți timp și să vă concentrați asupra altor sarcini importante în afacerea dvs. Vă vom oferi o experiență de utilizare simplă și intuitivă, care vă va permite să gestionați cu ușurință postările dvs. pe toate platformele de social media, într-un singur loc.'
        }
      />
    </VStack>
  );
};

export default HomePage;
